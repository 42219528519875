var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Data Display',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" Easily toggle visibility of almost any content on your pages in a vertically collapsing container. Includes support for making accordions. Visibility can be easily toggled with our "),_c('a',{attrs:{"href":"https://bootstrap-vue.org/docs/directives/toggle","target":"_blank"}},[_c('strong',[_vm._v("v-b-toggle directive")])]),_vm._v(", or via "),_c('code',[_vm._v("v-model")]),_vm._v(". ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('visibility')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }